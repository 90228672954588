<template>
  <div class="Orders bg-white">
    <Row class="px-1">
      <i-col span="24" class="align-justify">
        <i-col span="20" class="align-justify">
          <Button type="default" icon="md-refresh" size="large" class="mr-1" @click="reloadPage"></Button>
          <DatePicker
            v-model="dateRange"
            size="large"
            type="daterange"
            show-week-numbers
            :options="options2"
            placeholder="选择查询日期"
            class="mr-1"
            format="yyyy-MM-dd"
            :start-date="new Date()"
            @on-change="chooseTime"
          ></DatePicker>
          <i-input
            v-model="inputValue"
            size="large"
            placeholder="输入信息进行查找"
            style="width: 15rem"
            class="el-inline mr-1"
          >
            <Button slot="append" icon="md-search" style="width: 2.5rem" @click="handleSearch"></Button>
          </i-input>
          <Button type="success" icon="ios-trending-up" class="mr-1" @click="analysis">数据分析</Button>
        </i-col>
        <i-col span="4" class="align-right colHeigth">
          <Checkbox @on-change="changeStatus">被移除订单</Checkbox>
        </i-col>
        <i-col span="24" class="align-justify">
          <Table
            class="mt-1"
            border
            stripe
            context-menu
            show-context-menu
            ref="organTable"
            :loading="searching"
            :columns="tColumns"
            :data="tData"
            :height="theight"
          >
            <div slot-scope="{ row }" slot="action">
              <Button
                type="warning"
                style="margin-right:0.25rem"
                size="small"
                @click="refund(row)"
              >退款</Button>
              <Poptip
                confirm
                title="确定删除订单?"
                placement="top-end"
                @on-ok="deleteOrder(row)"
                @on-cancel="cancelDelete"
              >
                <Button style="pramary" size="small">删除</Button>
              </Poptip>
            </div>
          </Table>
          <Page
            ref="usersPagination"
            :total="recordTotal"
            :page-size="20"
            @on-change="changePage"
            @on-page-size-change="changeSize"
            show-total
            show-sizer
            show-elevator
            class="p-1 align-right"
          />
        </i-col>
      </i-col>
    </Row>
    <Drawer title="订单数据分析 - 更多财务信息请登录微信支付后台查看" v-model="draw" width="960" :styles="styles">
      <Row :gutter="32">
        <i-col span="24">
          <Form>
            <FormItem label="起止时间" label-position="top">
              <DatePicker
                type="daterange"
                :options="options2"
                placeholder="选择查询日期"
                size="large"
                class="mr-1"
              ></DatePicker>
            </FormItem>
          </Form>
        </i-col>
        <i-col span="24">
          <div id="c1"></div>
        </i-col>
      </Row>
    </Drawer>
    <Modal v-model="refundState" title="给用户退款" ok-text="确定" @on-ok="refundToUser">
      <Form :label-width="80" v-model="refundInfo">
        <Row class="px-1">
          <i-col span="24">
            <FormItem label="退款原因" label-position="top">
              <Input v-model="refundInfo.refundReason" size="large" placeholder="请输入退款原因" />
            </FormItem>
          </i-col>
          <i-col span="24">
            <i-input v-model="refundInfo.refundPrice" size="large">
              <Select v-model="priceType" slot="prepend" style="width: 80px">
                <Option value="1">成交价</Option>
                <Option value="2">自设价</Option>
              </Select>
            </i-input>
          </i-col>
        </Row>
      </Form>
    </Modal>
  </div>
</template>

<script>
import * as G2 from "@antv/g2";
export default {
  name: "Orders",
  data() {
    return {
      priceType: 1,
      refundInfo: {},
      refundState: false,
      userdata: [
        { genre: "Sports", sold: 275 },
        { genre: "Strategy", sold: 115 },
        { genre: "Action", sold: 120 },
        { genre: "Shooter", sold: 350 },
        { genre: "Other", sold: 150 },
      ],

      dateRange: "",
      timeRegion: [],
      draw: false,
      styles: {
        height: "calc(100% - 55px)",
        overflow: "auto",
        paddingBottom: "53px",
        position: "static",
      },
      inputValue: "",
      startTime: "",
      endTime: "",
      pageNum: 1,
      pageSize: 20,
      bindStatus: 1,

      recordTotal: 0,
      tData: [],
      searching: true,
      deOrRe: "移除",
      theight: window.innerHeight - 175,
      isShow: true,
      recordTotal: 0,
      tColumns: [
        {
          type: "selection",
          width: 35,
          align: "center",
        },
        {
          type: "index",
          title: "序号",
          width: 45,
          align: "center",
        },
        {
          title: "交易单号",
          key: "orderNo",
          minWidth: 100,
        },
        {
          title: "交易用户",
          key: "userName",
          minWidth: 60,
          tooltip: true,
        },
        {
          title: "交易类型",
          key: "orderType",
          minWidth: 60,
          render: (h, params) => {
            const row = params.row;
            const color =
              row.orderType === 1
                ? "primary"
                : row.orderType === 2
                ? "success"
                : row.orderType === 3
                ? "error"
                : "warning";
            const text =
              row.orderType === 1
                ? "量表"
                : row.orderType === 2
                ? "咨询"
                : row.orderType === 3
                ? "课程"
                : "活动";
            return h(
              "Tag",
              {
                props: {
                  type: "dot",
                  color: color,
                },
              },
              text
            );
          },
        },
        {
          title: "交易名称",
          key: "goodsName",
          minWidth: 60,
        },
        {
          title: "交易金额",
          key: "price",
          minWidth: 60,
        },
        {
          title: "订单状态",
          key: "orderStatus",
          minWidth: 60,
        },
        {
          title: "支付状态",
          key: "payStatus",
          minWidth: 60,
        },
        {
          title: "交易时间",
          key: "orderTime",
          minWidth: 100,
          render: (h, params) => {
            let dt =
              this.myjs.dateFormat(params.row.orderTime) +
              " " +
              this.myjs.timeFormat(params.row.orderTime);
            return h("span", dt);
          },
          sortable: true,
          sortType: "desc",
        },
        {
          title: "支付方式",
          key: "payType",
          minWidth: 120,
          render: (h, params) => {
            const color = "success";
            const text = params.row.payType;
            return h(
              "Tag",
              {
                props: {
                  type: "dot",
                  color: color,
                },
              },
              text
            );
          },
        },
        {
          title: "备注",
          key: "remarks",
          minWidth: 60,
        },
        {
          title: "操作",
          slot: "action",
          width: 102,
        },
      ],
      options2: {
        shortcuts: [
          {
            text: "一周",
            value() {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              return [start, end];
            },
          },
          {
            text: "一个月",
            value() {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              return [start, end];
            },
          },
          {
            text: "三个月",
            value() {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              return [start, end];
            },
          },
        ],
      },
    };
  },
  mounted() {
    window.addEventListener(
      "resize",
      () => (this.theight = window.innerHeight - 175),
      false
    );
    this.getOrders();
  },
  methods: {
    refundToUser() {},
    initChart() {
      const chart = new G2.Chart({
        container: "c1", // 指定图表容器 ID
        width: 960, // 指定图表宽度
        height: 300, // 指定图表高度
      });

      // Step 2: 载入数据源
      chart.source(this.userdata);
      // Step 3：创建图形语法，绘制柱状图，由 genre 和 sold 两个属性决定图形位置，genre 映射至 x 轴，sold 映射至 y 轴
      chart.interval().position("genre*sold").color("genre");
      // Step 4: 渲染图表
      chart.render();
    },
    getOrders() {
      this.searching = true;
      this.$get("Orders/GetOrderList", {
        startTime: this.startTime,
        endTime: this.endTime,
        keyword: this.inputValue,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        statusCode: this.bindStatus,
      }).then((res) => {
        this.recordTotal = res.data.total;
        this.tData = res.data.responseList;
        this.searching = false;
        if (res.data.code) {
          this.$Message.success({
            content: "加载成功",
            duration: 3,
          });
        } else {
          this.$Message.error({
            content: "加载失败，请稍后重试",
            duration: 3,
          });
        }
      });
    },
    cancelDelete() {},
    refund(row) {
      this.refundState = true;
    },
    deleteOrder(row) {
      console.log(row);
      this.$post("Orders/DeleteOrders", { orderId: row.orderId }).then(
        (res) => {
          if (res.data.code) {
            this.$Message.success({
              content: "删除成功",
              duration: 3,
            });
          } else {
            this.$Message.error({
              content: "删除失败，请稍后重试",
              duration: 3,
            });
          }
        }
      );
    },
    handleSearch() {
      this.getOrders();
    },
    chooseTime(e) {
      console.log(e);
      this.startTime = e[0];
      this.endTime = e[1];
      this.getOrders();
    },
    analysis() {
      this.draw = true;
      this.initChart();
    },
    changeStatus(k) {
      if (k) {
        this.deOrRe = "还原";
        this.bindStatus = 0;
      } else {
        this.deOrRe = "移除";
        this.bindStatus = 1;
      }
      this.pageNum = 1;
      this.getOrders();
    },
    changeSize(e) {
      this.pageSize = e;
      this.getOrders();
    },
    changePage(e) {
      this.pageNum = e;
      this.getOrders();
    },
    reloadPage() {
      this.pageSize = 20;
      this.pageNum = 1;
      this.inputValue = "";
      this.startTime = "";
      this.endTime = "";
      this.statusCode = 1;
      this.getOrders();
    },
  },
};
</script>

<style lang="less" scoped="scoped">
</style>
